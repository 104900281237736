"use strict";

$(document).ready(function() {
    $('#frmExport').formValidation({
        framework: "uikit",
        trigger: 'blur',
        fields: {
            export_begin: {
                trigger: 'blur change',
                validators: {
                    notEmpty: {
                        message: ' '
                    }
                }
            },
            export_end: {
                trigger: 'blur change',
                validators: {
                    notEmpty: {
                        message: ' '
                    }
                }
            }
        }
    });
});