"use strict";

$(document).ready(function() {
    $("#paymentSpecial").change(function() {
        $("#paymentSpecialFixed, #paymentSpecialSingle").prop("disabled", false);
        $("#paymentSpecialFixed").prop("checked", true);
        $("#donation_duration, #donation_amount, #donation_amount_recurring").prop("disabled", true);
        $("#donation_duration, #donation_amount, #donation_amount_recurring").val("");
    });

    $("#paymentRecurring").change(function() {
        $("#paymentSpecialFixed, #paymentSpecialSingle").prop("checked", false);
        $("#payment_special_amount, #donation_amount").val("");
        $("#paymentSpecialFixed, #paymentSpecialSingle, #payment_special_amount, #donation_amount").prop("disabled", true);
        $("#donation_duration, #donation_amount_recurring").prop("disabled", false);
    });

    $("#paymentOneTime").change(function() {
        $("#paymentSpecialFixed, #paymentSpecialSingle").prop("checked", false);
        $("#donation_duration, #payment_special_amount, #donation_amount_recurring").val("");
        $("#paymentSpecialFixed, #paymentSpecialSingle, #payment_special_amount, #donation_duration, #donation_amount_recurring").prop("disabled", true);
        $("#donation_amount").prop("disabled", false);
    });

    $("#paymentSpecialSingle").change(function() {
        $("#payment_special_amount").prop("disabled", false);
    });

    $("#paymentSpecialFixed").change(function() {
        $("#payment_special_amount").prop("disabled", true);
    });
});
